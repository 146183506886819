import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import WebSocketService from "./WebSocketService";
import { GlobalContext } from "./GlobalContext";
import { jwtDecode } from "jwt-decode";

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
    const [connectionStatus, setConnectionStatus] = useState(
        WebSocketService.ws ? WebSocketService.ws.readyState : WebSocket.CONNECTING
    );
    const { auth, refreshAccessToken } = useContext(GlobalContext);
    const { accessToken } = auth;

    useEffect(() => {
        if (window.Capacitor && accessToken) {
            window.capacitorToken = accessToken;
        }
    }, [accessToken]);

    // Handle WebSocket open
    const handleOpen = useCallback(() => {
        setConnectionStatus(WebSocket.OPEN);
        console.log("WebSocket opened");
    }, []);

    // Handle WebSocket close
    const handleClose = useCallback(
        (event) => {
            // If the server closes the connection due to authentication failure, refresh token.
            if (
                event.code === 1008 &&
                event.reason &&
                event.reason.includes("Authentication failed")
            ) {
                console.warn(
                    "WebSocket closed due to authentication failure. Refreshing token..."
                );
                refreshAccessToken().then((success) => {
                    if (success) {
                        console.log("Token refreshed after auth failure.");
                    } else {
                        console.error("Failed to refresh token after auth failure.");
                    }
                });
            }
            const wasClean = event && event.wasClean;
            setConnectionStatus(wasClean ? WebSocket.CLOSED : WebSocket.CONNECTING);
            console.log("WebSocket closed", JSON.stringify(event));
        },
        [refreshAccessToken]
    );

    // Handle WebSocket error
    const handleError = useCallback((error) => {
        console.error("WebSocket encountered error:", error);
    }, []);

    // Subscribe/unsubscribe to WebSocketService events
    useEffect(() => {
        WebSocketService.on("open", handleOpen);
        WebSocketService.on("close", handleClose);
        WebSocketService.on("error", handleError);
        return () => {
            WebSocketService.off("open", handleOpen);
            WebSocketService.off("close", handleClose);
            WebSocketService.off("error", handleError);
        };
    }, [handleOpen, handleClose, handleError]);

    // Send messages via the service
    const sendMessage = useCallback((message) => {
        WebSocketService.sendMessage(message);
    }, []);

    // When the connection is open (or token updates), verify token validity and send the authentication message.
    useEffect(() => {
        if (connectionStatus === WebSocket.OPEN && accessToken) {
            let tokenValid = true;
            try {
                const decoded = jwtDecode(accessToken);
                if (decoded.exp * 1000 < Date.now()) {
                    tokenValid = false;
                }
            } catch (error) {
                console.error("Error decoding access token:", error);
                tokenValid = false;
            }

            if (!tokenValid) {
                console.log(
                    "Access token expired, refreshing before authenticating WebSocket..."
                );
                refreshAccessToken().then((success) => {
                    if (success) {
                        console.log(
                            "Token refreshed successfully, sending authentication message"
                        );
                        WebSocketService.sendMessage({
                            type: "authenticate",
                            token: auth.accessToken,
                        });
                    } else {
                        console.error("Failed to refresh token for WebSocket authentication");
                    }
                });
            } else {
                WebSocketService.sendMessage({ type: "authenticate", token: accessToken });
            }
        }
    }, [connectionStatus, accessToken, refreshAccessToken, auth.accessToken]);

    const value = { connectionStatus, sendMessage };
    return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>;
};

export default WebSocketContext;
