import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";

function RootRedirector() {
  const { auth } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/conversation", { replace: true });
    } else {
      navigate("/home", { replace: true });
    }
  }, [auth, navigate]);

  return <div>Loading...</div>;
}

export default RootRedirector;
