import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { WebSocketProvider } from "./context/WebSocketContext";

import LoginPage from "./features/RegisterAndLoginPages/LoginPage";
import RegisterPage from "./features/RegisterAndLoginPages/RegisterPage";
import ResetPasswordPage from "./features/RegisterAndLoginPages/ResetPasswordPage";
import WelcomePage from "./features/WelcomePage/WelcomePage";
import ConversationPage from "./features/ConversationPage/ConversationPage";
import WordBankPage from "./features/WordBankPage/WordBankPage";
import ProgressDashboard from "./features/ProgressDashboard/ProgressDashboard";

import { GlobalProvider } from "./context/GlobalContext";
import ProtectedRoute from "./components/ProtectedRoute";
import RootRedirector from "./components/RootRedirector";

import "@material/web/menu/menu.js";
import "@material/web/menu/menu-item.js";
import "@material/web/menu/sub-menu.js";

import "@material/web/button/filled-button.js";
import "@material/web/button/outlined-button.js";
import "@material/web/button/text-button.js";
import "@material/web/button/elevated-button.js";
import "@material/web/button/filled-tonal-button.js";

import "@material/web/textfield/filled-text-field.js";
import "@material/web/textfield/outlined-text-field.js";

import "@material/web/icon/icon.js";
import "@material/web/iconbutton/filled-icon-button.js";
import "@material/web/iconbutton/filled-tonal-icon-button.js";
import "@material/web/iconbutton/icon-button.js";
import "@material/web/iconbutton/outlined-icon-button.js";

import "@material/web/elevation/elevation.js";

import "@material/web/checkbox/checkbox.js";

import "@material/web/dialog/dialog.js";

import "@material/web/select/outlined-select.js";
import "@material/web/select/filled-select.js";
import "@material/web/select/select-option.js";

import "@material/web/switch/switch.js";

// Use lazy loading for AdminPage
const AdminPage = lazy(() => import("./features/AdminPage/AdminPage"));

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <GlobalProvider>
            <WebSocketProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<RootRedirector />} />
                        <Route path="/home" element={<WelcomePage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route
                            path="/resetPassword"
                            element={
                                <ProtectedRoute>
                                    <ResetPasswordPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/conversation"
                            element={
                                <ProtectedRoute>
                                    <ConversationPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/wordBank"
                            element={
                                <ProtectedRoute>
                                    <WordBankPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/ProgressDashboard"
                            element={
                                <ProtectedRoute>
                                    <ProgressDashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/admin"
                            element={
                                <Suspense fallback={<div>Loading admin page...</div>}>
                                    <ProtectedRoute requiredRole="admin">
                                        <AdminPage />
                                    </ProtectedRoute>
                                </Suspense>
                            }
                        />
                    </Routes>
                </Router>
            </WebSocketProvider>
        </GlobalProvider>
    </React.StrictMode>
);

reportWebVitals();
