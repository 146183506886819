// ConversationSidePanelContent.js
import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import ScenarioManager from "../../components/ScenarioManager";
import InviteFriendDialog from "../../components/InviteFriendDialog";
import "./ConversationSidePanelContent.css";
import WebSocketService from "../../context/WebSocketService";

const ConversationSidePanelContent = ({
    conversationDetails,
    setConversationDetails,
    scenarioState,
    setScenarioState,
    handleResetConversation,
    isPanelOpen,
    setIsPanelOpen,
}) => {
    const [friendConversations, setFriendConversations] = useState([]);
    const [scenarioConversations, setScenarioConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const scenarioManagerRef = useRef(null);

    const dialogRefInvite = useRef(null);
    const openInviteDialog = () => {
        dialogRefInvite.current.open = true;
    };

    const openScenarioManager = () => {
        scenarioManagerRef.current.openScenarioManagerDefault();
    };

    const createAssistantConversation = async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}/create-petpal-conversation`, {
            withCredentials: true,
        });
        fetchConversations();
        // Optionally close the panel if needed
    };

    const fetchConversationById = useCallback(
        async (conversation) => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/get-conversation/${conversation._id}`
                );
                // Rename _id to conversationId and sentences to conversationHistory,
                // while extracting scenario separately and gathering remaining fields.
                const {
                    _id: conversationId,
                    sentences: conversationHistory,
                    scenario,
                    ...otherDetails
                } = response.data;
                // setConversationDetails without the scenario field
                setConversationDetails({
                    conversationId,
                    conversationHistory,
                    ...otherDetails,
                });
                // Store scenario separately
                setScenarioState(scenario);
                setSelectedConversation(conversation);
            } catch (error) {
                console.error("Error fetching conversation:", error);
            }
        },
        [setConversationDetails, setScenarioState]
    );

    const handleDeleteConversation = async (conversationId) => {
        try {
            if (conversationId === conversationDetails.conversationId) {
                handleResetConversation();
            }
            await axios.delete(
                `${process.env.REACT_APP_API_URL}/delete-conversation/${conversationId}`
            );
            fetchConversations();
        } catch (error) {
            console.error("Error deleting conversation:", error);
        }
    };
    // Add this function within your ConversationSidePanelContent component
    const updateConversationLanguage = async (conversationId) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/update-conversation-language`,
                { conversationId },
                { withCredentials: true }
            );
            // Optionally update local state with the new targetLanguage
            setConversationDetails((prev) => ({
                ...prev,
                targetLanguage: response.data.targetLanguage,
            }));
            console.log(response.data.message);
        } catch (error) {
            console.error("Error updating conversation language:", error);
        }
    };

    useEffect(() => {
        const handleMessage = (data) => {
            if (data.conversations) {
                const allConversations = data.conversations;
                // Friend conversations are those that do not have type "AI" or "none"
                setFriendConversations(
                    allConversations.filter(
                        (conv) =>
                            conv.conversationType !== "AI" && conv.conversationType !== "none"
                    )
                );
                // Group conversations with type "AI" or "none" as scenario conversations.
                setScenarioConversations(
                    allConversations.filter(
                        (conv) =>
                            conv.conversationType === "AI" || conv.conversationType === "none"
                    )
                );
            }
        };
        WebSocketService.on("message", handleMessage);
        return () => {
            WebSocketService.off("message", handleMessage);
        };
    }, []);

    const fetchConversations = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/get-conversation-titles`
            );
            const allConversations = response.data;
            // Friend conversations are those that do not have type "AI" or "none"
            setFriendConversations(
                allConversations.filter(
                    (conv) =>
                        conv.conversationType !== "AI" && conv.conversationType !== "none"
                )
            );
            // Group conversations with type "AI" or "none" as scenario conversations.
            setScenarioConversations(
                allConversations.filter(
                    (conv) =>
                        conv.conversationType === "AI" || conv.conversationType === "none"
                )
            );
        } catch (error) {
            console.error("Error fetching conversation titles:", error);
        }
    };

    useEffect(() => {
        fetchConversations();
    }, []);

    useEffect(() => {
        if (isPanelOpen) fetchConversations();
    }, [isPanelOpen]);

    useEffect(() => {
        const allConversations = [...friendConversations, ...scenarioConversations];
        const setupMenuToggle = (menuSelector, anchorSelector) => {
            const toggleMenu = () => {
                const menuEl = document.body.querySelector(menuSelector);
                if (menuEl) {
                    menuEl.open = !menuEl.open;
                }
            };
            const anchorEl = document.body.querySelector(anchorSelector);
            if (anchorEl) {
                anchorEl.addEventListener("click", toggleMenu);
            }
            return () => {
                if (anchorEl) {
                    anchorEl.removeEventListener("click", toggleMenu);
                }
            };
        };

        const cleanupFunctions = allConversations.map((conversation) => {
            const buttonId = `conversation-actions-menu-anchor-${conversation._id}`;
            const menuId = `conversation-actions-menu-${conversation._id}`;
            return setupMenuToggle(`#${menuId}`, `#${buttonId}`);
        });

        return () => {
            cleanupFunctions.forEach((cleanup) => cleanup());
        };
    }, [friendConversations, scenarioConversations]);

    return (
        <div className="side-panel-content">
            {/* Friends Section */}
            <h3 className="h3-select-conversation">
                <span>Friends</span>
                <md-icon-button onClick={openInviteDialog}>
                    <md-icon>person_add</md-icon>
                </md-icon-button>
            </h3>
            <ul>
                <li onClick={openInviteDialog}>
                    Invite a Friend
                    <md-icon>add</md-icon>
                </li>
                {friendConversations.map((conversation) => {
                    const buttonId = `conversation-actions-menu-anchor-${conversation._id}`;
                    const menuId = `conversation-actions-menu-${conversation._id}`;
                    return (
                        <li
                            key={conversation._id}
                            className={
                                conversation._id === selectedConversation?._id
                                    ? "selected"
                                    : ""
                            }
                            onClick={async () => {
                                await fetchConversationById(conversation);
                            }}
                        >
                            {conversation.title}
                            <span className="inline-flex-container">
                                <md-icon-button
                                    id={buttonId}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <md-icon>more_horiz</md-icon>
                                </md-icon-button>
                                <md-menu
                                    anchor-corner="start-end"
                                    menu-corner="start-end"
                                    id={menuId}
                                    anchor={buttonId}
                                >
                                    <md-menu-item
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteConversation(conversation._id);
                                        }}
                                    >
                                        <span style={{ color: "var(--md-sys-color-error)" }}>
                                            Delete
                                        </span>
                                        <md-icon
                                            style={{ color: "var(--md-sys-color-error)" }}
                                            slot="end"
                                        >
                                            delete
                                        </md-icon>
                                    </md-menu-item>
                                    <md-menu-item
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            updateConversationLanguage(conversation._id);
                                        }}
                                    >
                                        <span>Update Language</span>
                                        <md-icon slot="end">language</md-icon>
                                    </md-menu-item>
                                </md-menu>
                            </span>
                        </li>
                    );
                })}
            </ul>
            <InviteFriendDialog dialogRefInvite={dialogRefInvite} />

            {/* Scenario Conversations Section */}
            <h3 className="h3-select-conversation">
                <span>Scenario Conversations</span>
                <md-icon-button onClick={handleResetConversation}>
                    <md-icon>edit_square</md-icon>
                </md-icon-button>
            </h3>
            <ul>
                <li onClick={openScenarioManager}>
                    New Scenario
                    <md-icon>add</md-icon>
                </li>
                <li onClick={createAssistantConversation}>
                    Restart Tutorial
                    <md-icon>Help</md-icon>
                </li>
                {scenarioConversations.map((conversation) => {
                    const buttonId = `conversation-actions-menu-anchor-${conversation._id}`;
                    const menuId = `conversation-actions-menu-${conversation._id}`;
                    return (
                        <li
                            key={conversation._id}
                            className={
                                conversation._id === selectedConversation?._id
                                    ? "selected"
                                    : ""
                            }
                            onClick={async () => {
                                await fetchConversationById(conversation);
                            }}
                        >
                            {conversation.title}
                            <span className="inline-flex-container">
                                <md-icon-button
                                    id={buttonId}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <md-icon>more_horiz</md-icon>
                                </md-icon-button>
                                <md-menu
                                    anchor-corner="start-end"
                                    menu-corner="start-end"
                                    id={menuId}
                                    anchor={buttonId}
                                >
                                    <md-menu-item
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteConversation(conversation._id);
                                        }}
                                    >
                                        <span style={{ color: "var(--md-sys-color-error)" }}>
                                            Delete
                                        </span>
                                        <md-icon
                                            style={{ color: "var(--md-sys-color-error)" }}
                                            slot="end"
                                        >
                                            delete
                                        </md-icon>
                                    </md-menu-item>
                                    <md-menu-item
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            updateConversationLanguage(conversation._id);
                                        }}
                                    >
                                        <span>Update Language</span>
                                        <md-icon slot="end">language</md-icon>
                                    </md-menu-item>
                                </md-menu>
                            </span>
                        </li>
                    );
                })}
            </ul>
            <ScenarioManager
                ref={scenarioManagerRef}
                scenarioState={scenarioState}
                setScenarioState={setScenarioState}
                setIsPanelOpen={setIsPanelOpen}
            />
        </div>
    );
};

export default ConversationSidePanelContent;
