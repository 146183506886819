import React, { forwardRef, useRef, useEffect, useImperativeHandle } from "react";
import axios from "axios";
import AutoResizedTextField from "./mdc/AutoResizedTextField";
import WebSocketService from "../context/WebSocketService";

axios.defaults.withCredentials = true;
const ScenarioManager = forwardRef((props, ref) => {
    const { scenarioState, setScenarioState, setIsPanelOpen } = props;
    const dialogRefCustomization = useRef(null);
    const dialogRefConfirmation = useRef(null);

    const handleInputChange = (field, value) => {
        setScenarioState((prevDetails) => ({
            ...prevDetails,
            [field]: value,
        }));
    };

    const openScenarioManagerDefault = () => {
        handlePageChange("customization");
    };

    const openDialog = (dialogRef) => {
        if (dialogRef.current) {
            dialogRef.current.open = true;
        }
    };

    const closeDialog = (dialogRef) => {
        if (dialogRef.current) {
            dialogRef.current.open = false;
        }
    };

    useImperativeHandle(ref, () => ({
        openScenarioManagerDefault,
        openDialog: openDialog,
    }));

    const handlePageChange = (page) => {
        if (page === "customization") {
            openDialog(dialogRefCustomization);
            closeDialog(dialogRefConfirmation);
        } else if (page === "confirmation") {
            openDialog(dialogRefConfirmation);
            closeDialog(dialogRefCustomization);
        }
    };

    const createScenario = async () => {
        const scenarioData = {
            reasonForLearning: scenarioState.reasonForLearning,
            additionalNotes: scenarioState.additionalNotes,
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/create-scenario`,
                scenarioData,
                {
                    withCredentials: true,
                }
            );

            const responseData = response.data;

            setScenarioState((prevDetails) => ({
                ...prevDetails,
                ...responseData,
            }));
        } catch (error) {
            console.error(
                "Error creating the scenario:",
                error.response ? error.response.data : error.message
            );
        }
    };

    const areFieldsEmpty = (fields) => {
        return fields.every((field) => !field || field.trim() === "");
    };

    const handleNext = async () => {
        handlePageChange("confirmation");

        const fieldsToCheck = [
            scenarioState.languageLearnerRole,
            scenarioState.conversationPartnerRole,
            scenarioState.informationToGive,
            scenarioState.informationToRequest,
            scenarioState.context,
        ];

        if (areFieldsEmpty(fieldsToCheck)) {
            createScenario();
        }
    };

    const handleBack = () => {
        handlePageChange("customization");
    };

    const handleConfirm = async () => {
        closeDialog(dialogRefConfirmation);
        setIsPanelOpen(false);
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/confirm-scenario`,
                scenarioState,
                {
                    withCredentials: true,
                }
            );
        } catch (error) {
            console.error(
                "Error updating the scenario:",
                error.response ? error.response.data : error.message
            );
        }
    };
    return (
        <span className="scenario-manager">
            <md-dialog ref={dialogRefCustomization}>
                <CustomizeScenarioComponent
                    handleInputChange={handleInputChange}
                    handleNext={handleNext}
                    scenarioState={scenarioState}
                    setScenarioState={setScenarioState}
                />
            </md-dialog>
            <md-dialog ref={dialogRefConfirmation}>
                <ConfirmScenarioComponent
                    handleInputChange={handleInputChange}
                    handleBack={handleBack}
                    handleConfirm={handleConfirm}
                    createScenario={createScenario}
                    setScenarioState={setScenarioState}
                    scenarioState={scenarioState}
                />
            </md-dialog>
        </span>
    );
});

const CustomizeScenarioComponent = ({
    handleInputChange,
    handleNext,
    scenarioState,
    setScenarioState,
}) => {
    const resetCustomization = async () => {
        setScenarioState((prevDetails) => ({
            ...prevDetails,
            reasonForLearning: "",
            additionalNotes: "",
        }));
    };

    return (
        <>
            <span slot="headline" className="headline">
                Customize Your Scenario
                <md-icon-button
                    form="scenarioForm"
                    value="close"
                    aria-label="Close dialog"
                    class="dialog-close-button"
                >
                    <md-icon>close</md-icon>
                </md-icon-button>
            </span>
            <form id="scenarioForm" slot="content" method="dialog">
                <div className="scenario-manager-text-field">
                    <AutoResizedTextField
                        value={scenarioState.reasonForLearning}
                        onInput={(e) => {
                            handleInputChange("reasonForLearning", e.target.value);
                        }}
                        editable
                        label="Reason for learning"
                        placeholder="e.g., Business, Travel, Hobby"
                        autofocus
                    />
                </div>
                <div className="scenario-manager-text-field">
                    <AutoResizedTextField
                        type="textarea"
                        minRows={3}
                        maxRows={6}
                        value={scenarioState.additionalNotes}
                        onInput={(e) => {
                            handleInputChange("additionalNotes", e.target.value);
                        }}
                        editable
                        label="Additional notes"
                        placeholder="e.g., Preferred topics, etc."
                    />
                </div>
            </form>
            <div slot="actions">
                <md-text-button onClick={resetCustomization}>Clear</md-text-button>
                <md-filled-button onClick={handleNext}>Next</md-filled-button>
            </div>
        </>
    );
};

const ConfirmScenarioComponent = ({
    handleInputChange,
    handleBack,
    handleConfirm,
    createScenario,
    scenarioState,
    setScenarioState,
}) => {
    const resetConfirmation = async () => {
        setScenarioState((prevDetails) => ({
            ...prevDetails,
            languageLearnerRole: "",
            conversationPartnerRole: "",
            informationToGive: "",
            informationToRequest: "",
            context: "",
        }));
        createScenario();
    };
    useEffect(() => {
        // Handler for messages that contain a "target"
        const handleTargetMessage = (data) => {
            if (data.target === "scenarioSnapshot" && data.value) {
                setScenarioState((prevState) => ({
                    ...prevState,
                    ...data.value,
                }));
            }
        };

        // Subscribe to "message:target" event
        WebSocketService.on("message:target", handleTargetMessage);

        // Cleanup subscription on unmount
        return () => {
            WebSocketService.off("message:target", handleTargetMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <span slot="headline" className="headline">
                Review Your Scenario
            </span>
            <form id="confirmationForm" slot="content" method="dialog">
                <div className="scenario-manager-text-field">
                    <AutoResizedTextField
                        required
                        value={scenarioState.languageLearnerRole}
                        onInput={(e) => {
                            handleInputChange("languageLearnerRole", e.target.value);
                        }}
                        editable
                        label="Language Learner Role"
                        placeholder="e.g., Student, Tourist"
                    />
                </div>
                <div className="scenario-manager-text-field">
                    <AutoResizedTextField
                        required
                        value={scenarioState.conversationPartnerRole}
                        onInput={(e) => {
                            handleInputChange("conversationPartnerRole", e.target.value);
                        }}
                        editable
                        label="Conversation Partner Role"
                        placeholder="e.g., Teacher, Local Resident"
                    />
                </div>
                <div className="scenario-manager-text-field">
                    <AutoResizedTextField
                        type="textarea"
                        minRows={2}
                        value={scenarioState.informationToGive}
                        onInput={(e) => {
                            handleInputChange("informationToGive", e.target.value);
                        }}
                        editable
                        label="Information to Give"
                        placeholder="e.g., Personal details, Preferences"
                    />
                </div>
                <div className="scenario-manager-text-field">
                    <AutoResizedTextField
                        type="textarea"
                        minRows={2}
                        value={scenarioState.informationToRequest}
                        onInput={(e) => {
                            handleInputChange("informationToRequest", e.target.value);
                        }}
                        editable
                        label="Information to Request"
                        placeholder="e.g., Directions, Recommendations"
                    />
                </div>
                <div className="scenario-manager-text-field">
                    <AutoResizedTextField
                        type="textarea"
                        minRows={3}
                        value={scenarioState.context}
                        onInput={(e) => {
                            handleInputChange("context", e.target.value);
                        }}
                        editable
                        label="Context"
                        placeholder="e.g., Formal meeting, Casual conversation"
                    />
                </div>
            </form>
            <div slot="actions">
                <md-text-button onClick={resetConfirmation}>New Scenario</md-text-button>
                <md-text-button onClick={handleBack}>Back</md-text-button>
                <md-filled-button autofocus onClick={handleConfirm}>
                    Confirm
                </md-filled-button>
            </div>
        </>
    );
};

export default ScenarioManager;
