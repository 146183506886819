import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import "../../App.css";
import axios from "axios";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";

import WebSocketService from "../../context/WebSocketService.js";

import ConversationArea from "./ConversationArea.jsx";
import { GlobalContext } from "../../context/GlobalContext.js";

import SidePanel from "../../components/SidePanel/SidePanel.js";
import ConversationSidePanelContent from "./ConversationSidePanelContent.js";
import MessageInput from "./MessageInput.js";
import ScenarioGoals from "./ScenarioGoals.js";

axios.defaults.withCredentials = true;

function ConversationPage() {
    const [conversationDetails, setConversationDetails] = useState({
        conversationId: "",
        conversationHistory: [],
        persona: "",
    });
    const [scenarioState, setScenarioState] = useState({
        reasonForLearning: "",
        additionalNotes: "",
        languageLearnerRole: "",
        conversationPartnerRole: "",
        informationToGive: "",
        informationToRequest: "",
        context: "",
    });

    const [conversationHistory, setConversationHistory] = useState([]);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const { auth, preferences, setPreferences } = useContext(GlobalContext);

    const menuButtonRef = useRef(null);
    const messageInputRef = useRef(null);

    const togglePanel = useCallback(() => {
        setIsPanelOpen((prevIsOpen) => !prevIsOpen);
    }, []);

    useEffect(() => {
        if (preferences.theme) {
            import(`../../themes/${preferences.theme}.css`)
                .then(() => {})
                .catch((err) => {
                    console.error(`Failed to load ${preferences.theme} theme`, err);
                });
            document.body.className = preferences.theme;
        }
    }, [preferences.theme]);

    // Helper to convert VAPID public key from base64 to UInt8Array.
    function urlB64ToUint8Array(base64String) {
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    useEffect(() => {
        if (Capacitor.getPlatform() !== "web") {
            // Native push registration (Android/iOS)
            PushNotifications.requestPermissions().then((permission) => {
                if (permission.receive === "granted") {
                    PushNotifications.register();
                } else {
                    console.warn("Native push notifications permission not granted");
                }
            });

            // Listen for native push events
            PushNotifications.addListener("registration", (token) => {
                console.log("Native push token:", token.value);
                // Send native token to your backend for storage
                axios
                    .post(`${process.env.REACT_APP_API_URL}/register-push`, {
                        token: token.value,
                    })
                    .catch((error) =>
                        console.error("Error registering native push token:", error)
                    );
            });

            PushNotifications.addListener("registrationError", (error) => {
                console.error("Error during native push registration:", error);
            });

            PushNotifications.addListener("pushNotificationReceived", (notification) => {
                console.log("Native push notification received:", notification);
                // Process the notification as needed
            });

            PushNotifications.addListener(
                "pushNotificationActionPerformed",
                (notification) => {
                    console.log("Native push notification action performed:", notification);
                    // For example, navigate the user to a specific page
                }
            );
        } else {
            // Fallback to web push notifications using service worker
            if ("serviceWorker" in navigator && "PushManager" in window) {
                navigator.serviceWorker
                    .register("/sw.js")
                    .then((registration) => {
                        return registration.pushManager
                            .getSubscription()
                            .then((subscription) => {
                                if (subscription) return subscription;
                                const convertedVapidKey = urlB64ToUint8Array(
                                    process.env.REACT_APP_VAPID_PUBLIC_KEY
                                );
                                return registration.pushManager.subscribe({
                                    userVisibleOnly: true,
                                    applicationServerKey: convertedVapidKey,
                                });
                            });
                    })
                    .then((subscription) => {
                        // Send the web push subscription to the server for storage
                        axios
                            .post(`${process.env.REACT_APP_API_URL}/register-push`, {
                                subscription,
                            })
                            .catch((error) =>
                                console.error(
                                    "Error registering web push subscription:",
                                    error
                                )
                            );
                    })
                    .catch((err) => console.error("Service Worker registration failed:", err));
            }
        }
    }, []);

    useEffect(() => {
        if (conversationDetails.conversationId) {
            WebSocketService.sendMessage({
                type: "subscribe",
                conversationId: conversationDetails.conversationId,
            });
        } else {
            // Send an unsubscribe message if conversationId is null
            handleResetConversation();
            WebSocketService.sendMessage({
                type: "unsubscribe",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationDetails.conversationId]);

    // Populate conversationHistory from parent prop
    useEffect(() => {
        // Retrieve new history from conversationDetails or default to an empty array.
        const newServerHistory =
            conversationDetails && Array.isArray(conversationDetails.conversationHistory)
                ? conversationDetails.conversationHistory
                : [];

        // Map new server messages to your local format.
        const newFormattedHistory = newServerHistory.map((message) => {
            const {
                id,
                targetLanguageMessage,
                originalMessage,
                morphemeTranslationDetails,
                fullSentenceTranslation,
            } = message;
            // Helper to structure each field
            const structureField = (value) => ({
                status: "loaded",
                statusMessage: null,
                value,
            });
            return {
                id,
                senderId: message.senderId,
                senderType: message.senderType,
                // Optionally include other properties...
                ...(targetLanguageMessage && {
                    targetLanguageMessage: structureField(targetLanguageMessage),
                }),
                ...(originalMessage && {
                    originalMessage: structureField(originalMessage),
                }),
                ...(morphemeTranslationDetails && {
                    morphemeTranslationDetails: structureField(morphemeTranslationDetails),
                }),
                ...(fullSentenceTranslation && {
                    fullSentenceTranslation: structureField(fullSentenceTranslation),
                }),
            };
        });

        // Preserve current temporary messages (e.g., those with id starting with "temp-")
        const tempMessages = conversationHistory.filter((msg) => msg.id.startsWith("temp-"));

        // Merge: replace all non-temp messages with the new history, but keep the temp ones.
        setConversationHistory([...newFormattedHistory, ...tempMessages]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationDetails]);

    useEffect(() => {
        const fetchConversationHistory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/request-conversation-history`
                );
                const { conversationDetails, preferences } = response.data;
                // Destructure conversationDetails: rename _id and sentences, extract scenario, and collect other fields
                const {
                    _id: conversationId,
                    sentences: conversationHistory,
                    scenario,
                    ...otherDetails
                } = conversationDetails;
                // Set conversationDetails without the scenario field
                setConversationDetails({
                    conversationId,
                    conversationHistory,
                    ...otherDetails,
                });
                // Store scenario separately
                setScenarioState(scenario);
                setPreferences(preferences);
            } catch (error) {
                console.error("Error fetching conversation history:", error);
            }
        };
        fetchConversationHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleMessage = (data) => {
            if (data.conversationDetails) {
                const {
                    _id: conversationId,
                    sentences: conversationHistory,
                    scenario,
                    ...otherDetails
                } = data.conversationDetails;
                setConversationDetails({
                    conversationId,
                    conversationHistory,
                    ...otherDetails,
                });
                setScenarioState(scenario);
            }
            if (data.preferences) {
                setPreferences(data.preferences);
            }
        };

        WebSocketService.on("message", handleMessage);

        return () => {
            WebSocketService.off("message", handleMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleResetConversation = async () => {
        // Reset the message input using the exposed ref function
        if (messageInputRef.current) {
            messageInputRef.current.reset();
        }
        setConversationDetails({
            conversationId: null,
            conversationHistory: [],
        });
        setConversationHistory([]);
        setScenarioState({
            reasonForLearning: "",
            additionalNotes: "",
            languageLearnerRole: "",
            conversationPartnerRole: "",
            informationToGive: "",
            informationToRequest: "",
            context: "",
        });
        setIsPanelOpen(false);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/reset-conversation`);
        } catch (error) {
            console.error("Error resetting conversation:", error);
        }
    };

    return (
        <div className={preferences.theme}>
            <div className="app">
                <div className="app-container">
                    <header className="app-header">
                        <span className="navigation-menu-button">
                            <md-icon-button ref={menuButtonRef} onClick={togglePanel}>
                                <md-icon>menu</md-icon>
                            </md-icon-button>
                        </span>
                    </header>
                    {scenarioState.languageLearnerRole !== "" && (
                        <ScenarioGoals scenarioState={scenarioState} />
                    )}
                    <SidePanel
                        isOpen={isPanelOpen}
                        togglePanel={togglePanel}
                        navigationItems={[
                            { label: "Home", path: "/home" },
                            { label: "Word Bank", path: "/wordBank" },
                            { label: "Progress Dashboard", path: "/progressDashboard" },
                            ...(auth.userType === "admin"
                                ? [{ label: "Admin Page", path: "/admin" }]
                                : []),
                        ]}
                        menuButtonRef={menuButtonRef}
                        className="app-side-panel"
                        preferences={preferences}
                    >
                        <ConversationSidePanelContent
                            conversationDetails={conversationDetails}
                            setConversationDetails={setConversationDetails}
                            scenarioState={scenarioState}
                            setScenarioState={setScenarioState}
                            handleResetConversation={handleResetConversation}
                            isPanelOpen={isPanelOpen}
                            setIsPanelOpen={setIsPanelOpen}
                        />
                    </SidePanel>
                    <ConversationArea
                        preferences={preferences}
                        setPreferences={setPreferences}
                        conversationHistory={conversationHistory}
                        setConversationHistory={setConversationHistory}
                        conversationDetails={conversationDetails}
                    />
                    <MessageInput
                        ref={messageInputRef}
                        conversationDetails={conversationDetails}
                        conversationHistory={conversationHistory}
                        setConversationHistory={setConversationHistory}
                        preferences={preferences}
                    />
                </div>
            </div>
        </div>
    );
}

export default ConversationPage;
