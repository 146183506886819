// ProtectedRoute.js
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, requiredRole }) => {
    const { auth, refreshAccessToken } = useContext(GlobalContext);
    const [isVerifying, setIsVerifying] = useState(true);

    useEffect(() => {
        const verifyAuth = async () => {
            // If not authenticated, try refreshing the token
            if (!auth.isAuthenticated) {
                const success = await refreshAccessToken();
                if (!success) {
                    // If refresh failed, verification is complete and we know the user isn’t authenticated
                    setIsVerifying(false);
                    return;
                }
            }
            setIsVerifying(false);
        };

        verifyAuth();
    }, [auth.isAuthenticated, refreshAccessToken]);

    if (isVerifying) {
        return <div>Loading...</div>;
    }

    // After verification, if the user is still not authenticated, redirect to login
    if (!auth.isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    if (requiredRole && auth.userType !== requiredRole) {
        return <Navigate to="/login" replace />;
    }

    return children;
};

export default ProtectedRoute;
