import { EventEmitter } from "events";

// Singleton wrapper for a WebSocket connection
class WebSocketService extends EventEmitter {
    static instance = null;

    static getInstance() {
        if (!WebSocketService.instance) {
            WebSocketService.instance = new WebSocketService();
        }
        return WebSocketService.instance;
    }

    constructor() {
        super();
        // If a singleton instance already exists, return it
        if (WebSocketService.instance) {
            return WebSocketService.instance;
        }

        this.ws = null;
        this.reconnectAttempts = 0;
        this.maxReconnectDelay = 30000; // 30 seconds
        this.heartbeatInterval = null;
        this.heartbeatIntervalTime = 30000; // 30 seconds
        this.shouldReconnect = true; // Flag to control auto-reconnect
        this.isReconnecting = false; // Flag to prevent overlapping reconnect attempts

        // Start monitoring the connection every 5 seconds
        this.connectionMonitorInterval = setInterval(() => {
            if (
                this.shouldReconnect &&
                (!this.ws ||
                    (this.ws.readyState !== WebSocket.OPEN &&
                        this.ws.readyState !== WebSocket.CONNECTING))
            ) {
                if (!this.isReconnecting) {
                    console.log("Connection inactive, attempting automatic reconnect");
                    this.handleReconnect();
                }
            }
        }, 5000);

        // Listen for the online event to trigger reconnection
        window.addEventListener("online", () => {
            if (
                !this.ws ||
                (this.ws.readyState !== WebSocket.OPEN &&
                    this.ws.readyState !== WebSocket.CONNECTING)
            ) {
                this.handleReconnect();
            }
        });

        // Listen for visibility change to trigger reconnection when the tab becomes active
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                if (
                    !this.ws ||
                    (this.ws.readyState !== WebSocket.OPEN &&
                        this.ws.readyState !== WebSocket.CONNECTING)
                ) {
                    this.handleReconnect();
                }
            }
        });

        // Bind methods
        this.handleReconnect = this.handleReconnect.bind(this);
        this.connectWebSocket = this.connectWebSocket.bind(this);
        this.initializeConnection();

        WebSocketService.instance = this;
    }

    initializeConnection() {
        this.initializeCookieAndConnect();
    }

    initializeCookieAndConnect() {
        if (!this.shouldReconnect) return; // Guard against reconnecting after logout

        fetch(`${process.env.REACT_APP_API_URL}/set-cookie`, {
            method: "GET",
            credentials: "include",
        })
            .then(() => {
                this.connectWebSocket();
            })
            .catch((error) => {
                console.error("Error setting cookie:", error);
                // If offline, warn and wait for the online event to trigger reconnection
                if (!navigator.onLine) {
                    console.warn("Offline: will not attempt reconnection until online");
                } else {
                    // Delay the reconnect attempt to allow transient issues to resolve
                    setTimeout(() => {
                        this.handleReconnect();
                    }, 3000);
                }
            });
    }

    connectWebSocket() {
        // If already connecting or open, do nothing
        if (this.ws) {
            if (
                this.ws.readyState === WebSocket.OPEN ||
                this.ws.readyState === WebSocket.CONNECTING
            ) {
                return;
            }
            this.ws = null;
        }

        // Build the websocket URL and append token if running on Capacitor
        let wsUrl = process.env.REACT_APP_WS_URL;
        // Detect if running on Capacitor by checking for window.Capacitor (this should be available in a native app)
        if (window.Capacitor && window.capacitorToken) {
            try {
                const urlObj = new URL(wsUrl);
                urlObj.searchParams.set("token", window.capacitorToken);
                wsUrl = urlObj.toString();
            } catch (e) {
                console.error("Error appending token to ws URL", e);
            }
        }
        this.ws = new WebSocket(wsUrl);

        this.ws.onopen = () => {
            this.reconnectAttempts = 0;
            this.isReconnecting = false; // Reset flag on successful connection
            this.startHeartbeat();
            this.emit("open");
            console.log("WebSocket connected");
        };

        this.ws.onerror = (error) => {
            console.error("WebSocket error:", error);
            this.emit("error", error);
        };

        this.ws.onclose = (event) => {
            this.stopHeartbeat();
            this.ws = null;
            if (this.shouldReconnect) {
                this.handleReconnect();
            }
            this.emit("close", event);
        };

        this.ws.onmessage = (event) => {
            if (!event.data) return;
            try {
                const messageObj = JSON.parse(event.data);

                // Always emit the generic "message" event
                this.emit("message", messageObj);

                // If there's a target property, emit "message:target"
                if (messageObj.target) {
                    this.emit("message:target", messageObj);
                }
                // You could also emit more specific events if you’d like:
                // if (messageObj.conversationDetails)  this.emit("message:conversationDetails", messageObj);
                // if (messageObj.title)                this.emit("message:title", messageObj);
                // if (messageObj.preferences)          this.emit("message:preferences", messageObj);
                // if (messageObj.userType)            this.emit("message:userType", messageObj);
                // if (messageObj.username)            this.emit("message:username", messageObj);
            } catch (error) {
                console.error("Error parsing message:", error);
            }
        };
    }

    handleReconnect() {
        if (!this.shouldReconnect) return;
        // Do not attempt reconnect if offline
        if (!navigator.onLine) {
            console.warn("Offline: postponing reconnect");
            return;
        }
        // If there is already an active or pending connection, do nothing.
        if (
            this.ws &&
            (this.ws.readyState === WebSocket.OPEN ||
                this.ws.readyState === WebSocket.CONNECTING)
        ) {
            return;
        }
        if (this.isReconnecting) return; // Avoid duplicate reconnects

        this.isReconnecting = true;
        this.reconnectAttempts += 1;
        const backoffDelay = Math.min(
            1000 * 2 ** this.reconnectAttempts,
            this.maxReconnectDelay
        );
        console.log(`Attempting to reconnect in ${backoffDelay / 1000} seconds`);

        setTimeout(() => {
            // Double-check before reconnecting.
            if (
                this.shouldReconnect &&
                (!this.ws ||
                    (this.ws.readyState !== WebSocket.OPEN &&
                        this.ws.readyState !== WebSocket.CONNECTING))
            ) {
                this.initializeCookieAndConnect();
            }
            this.isReconnecting = false;
        }, backoffDelay);
    }

    startHeartbeat() {
        this.stopHeartbeat();
        this.heartbeatInterval = setInterval(() => {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                const pingMessage = JSON.stringify({ type: "ping" });
                this.ws.send(pingMessage);
            }
        }, this.heartbeatIntervalTime);
    }

    stopHeartbeat() {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
            this.heartbeatInterval = null;
        }
    }

    sendMessage(message) {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify(message));
        } else {
            console.warn("WebSocket is not open. Unable to send message:", message);
        }
    }

    // Intentionally close the websocket and prevent reconnection
    closeWebSocket() {
        this.shouldReconnect = false;
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    }
}

export default WebSocketService.getInstance();
