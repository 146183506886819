import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import "../../App.css";
import "./WelcomePage.css";

function WelcomePage() {
    function adjustBannerAngle() {
        const elements = document.querySelectorAll(".construction-wrap");

        elements.forEach((element) => {
            const width = element.offsetWidth;
            const height = element.offsetHeight;

            // Calculate angle based on the aspect ratio
            const angle = Math.atan(height / width) * (180 / Math.PI);

            // Set the angle for the ::before pseudo-element
            const beforeElementStyle = `rotate(${angle}deg)`;
            element.style.setProperty("--banner-angle", beforeElementStyle);
        });
    }

    useEffect(() => {
        // Initial adjustment
        adjustBannerAngle();

        // Adjust on window resize
        window.addEventListener("resize", adjustBannerAngle);
    }, []);
    useEffect(() => {
        import(`../../themes/dark.css`)
            .then(() => {})
            .catch((err) => {
                console.error(`Failed to load dark theme`, err);
            });
        document.body.className = "dark";
    }, []);

    return (
        <div className="welcome">
            <Header />
            <HeroSection />
            <HowItWorksSection />
            <AppClarification />
            <Philosophy />
            <FAQSection />
            <FinalCTASection />
            {/* <div className="construction-wrap">
                <Testimonials />
            </div>
            <div className="construction-wrap">
                <PricingAndPlansSection />
            </div>
            <div className="construction-wrap">
                <InteractiveDemoSection />
            </div>
            
            <div className="construction-wrap">
                <Footer />
            </div> */}
        </div>
    );
}

function Header() {
    return (
        <header className="welcome-header">
            <Link to="/" className="home-button">
                Palyglot
            </Link>
            <div className="navigation-links">
                <Link to="/register">Register</Link> |<Link to="/login">Login</Link> |
            </div>
        </header>
    );
}
function HeroSection() {
    return (
        <div className="hero-section">
            <h1>
                <s>Learn to Communicate</s>
                <br />
                Communicate to Learn
            </h1>
            <p>
                Learn only what you need through tailored conversations with AI, not
                flashcards.
                <br />
                Overcome language barriers without the fear of minor errors holding you back.
            </p>
            <p>
                <b>Perfect is the enemy of progress.</b>
            </p>
            <Link to="/register" className="cta-button-link">
                <md-filled-button className="cta-button">Get Started</md-filled-button>
            </Link>
        </div>
    );
}
function HowItWorksSection() {
    return (
        <div className="how-it-works-section">
            <h2>How It Works</h2>
            <div className="steps-container">
                <div className="step">
                    <div className="step-icon">
                        <md-icon>flag</md-icon>
                    </div>
                    <h3>Set Your Learning Goals</h3>
                    <p>
                        Specify your reasons for learning a new language to personalize your
                        learning path.
                    </p>
                </div>
                <div className="step">
                    <div className="step-icon">
                        <md-icon>chat</md-icon>
                    </div>
                    <h3>Engage in Tailored Conversations</h3>
                    <p>
                        Participate in conversations generated by our scenario manager,
                        targeting your learning objectives.
                    </p>
                </div>
                <div className="step">
                    <div className="step-icon">
                        <md-icon>translate</md-icon>
                    </div>
                    <h3>Learn Vocabulary Relevant to You</h3>
                    <p>
                        Master the vocabulary you need through targeted conversations, with key
                        words highlighted for efficient learning.
                    </p>
                </div>
            </div>
        </div>
    );
}
function AppClarification() {
    return (
        <div className="app-clarification-section">
            <h2>What This App Does (and Doesn't) Teach</h2>
            <div className="clarification-wrapper">
                <div className="clarification">
                    <h3>Core Focus</h3>
                    <p>
                        Vocabulary, exposure, and practice through reading and writing in the
                        target language. Grammar is picked up through usage rather than
                        explicit lessons.
                    </p>
                </div>
                <div className="clarification">
                    <h3>Not a Pronunciation Tool</h3>
                    <p>Speaking and listening practice are not the primary focus.</p>
                </div>
            </div>
            <div className="clarification-wrapper">
                <div className="clarification">
                    <h3>No Alphabet & Minimal Reading Instruction</h3>
                    <p>
                        You may incidentally learn reading and writing but it's not explicitly
                        taught here.
                    </p>
                </div>
                <div className="clarification">
                    <h3>Planned Features</h3>
                    <p>
                        Future features include PenPal direct messaging, grammar syntax
                        explanations, cultural nuance explanations, and common mistake
                        analysis.
                    </p>
                </div>
            </div>
        </div>
    );
}
function Philosophy() {
    return (
        <div className="philosophy-section">
            <div className="learning-philosophy">
                <h2>Learning Philosophy</h2>
                <ul>
                    <li>
                        <strong>Progress over Perfection:</strong>
                        <br />
                        Perfection is not required to be understood. Time spent on perfection
                        is time not spent on progress.
                    </li>
                    <li>
                        <strong>Learn What You Need:</strong>
                        <br />
                        Everyone has a different reason for learning. Learn vocabulary that
                        helps you achieve <b>your</b> goals.
                    </li>
                    <li>
                        <strong>Confidence & Real-World Application:</strong>
                        <br />
                        Palyglot's goal is to give you the skills and confidence to have real
                        conversations with real people.
                    </li>
                </ul>
            </div>

            <div className="development-philosophy">
                <h2>Development Philosophy</h2>
                <ul>
                    <li>
                        <strong>Single Tool, Not a Multi-Tool:</strong>
                        <br />
                        Much like a flashcard app (e.g., Anki), this tool concentrates on a
                        specific learning loop—vocabulary building and text-based practice.
                    </li>
                    <li>
                        <strong>Text-to-Speech:</strong>
                        <br />
                        Not available yet; implementing it would dilute the focus and resources
                        of this app.
                    </li>
                    <li>
                        <strong>Not a Complete Path to Fluency:</strong>
                        <br />
                        Use this alongside other resources for speaking, listening, and more
                        advanced language skills.
                    </li>
                </ul>
            </div>
        </div>
    );
}
/* function Testimonials() {
    return (
        <div className="testimonials-section">
            <h2 className="testimonials-title">What Our Learners Say</h2>
            <div className="testimonials-container">
                { Example of a single testimonial }
                <div className="testimonial">
                    <p className="testimonial-text">
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ac
                        mattis metus. Quisque viverra, arcu sed gravida consectetur, velit arcu
                        convallis nunc, at fermentum lacus quam at sapien. Nunc accumsan
                        consectetur suscipit. Fusce est purus, efficitur vel maximus id,
                        iaculis at lacus."
                    </p>
                    <p className="testimonial-author">- John Doe</p>
                </div>
                { Repeat for other testimonials }
            </div>
        </div>
    );
} */
/* function InteractiveDemoSection() {
    return (
        <div className="interactive-demo-section">
            <h2>Try a Quick Demo</h2>
            <p>Enter your learning goal to generate a conversation scenario.</p>
            <input
                type="text"
                placeholder="Type your learning goal here..."
                className="demo-input"
            />
            <button className="demo-submit-button">Generate Scenario</button>
            <div className="demo-output">
                {Simulated conversation output will go here}
            </div>
        </div>
    );
} */

/* function PricingAndPlansSection() {
    return (
        <div className="pricing-section">
            <h2>Choose Your Plan</h2>
            <div className="plans-container">
                <div className="plan">
                    <h3>Basic</h3>
                    <p>Perfect for beginners looking to get started.</p>
                    <ul>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                    </ul>
                    <button>Subscribe</button>
                </div>
                <div className="plan">
                    <h3>Premium</h3>
                    <p>For dedicated learners wanting to accelerate their progress.</p>
                    <ul>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Lorem ipsum dolor sit amet</li>
                    </ul>
                    <button>Subscribe</button>
                </div>
                <div className="plan">
                    <h3>Supporter</h3>
                    <p>
                        For learners wanting to support free tier users and the app's
                        development.
                    </p>
                    <ul>
                        <li>Supports free tier users.</li>
                        <li>Prioratized feedback and technical support.</li>
                        <li>Supporter tier forms for discussion.</li>
                        <li>Lorem ipsum dolor sit amet</li>
                    </ul>
                    <button>Subscribe</button>
                </div>
            </div>
        </div>
    );
} */
function FAQSection() {
    return (
        <div className="faq-section">
            <h2 className="faq-heading">Frequently Asked Questions</h2>
            <div className="faq-container">
                <div className="faq-item">
                    <h3 className="faq-question">What languages are supported?</h3>
                    <p className="faq-answer">
                        The app supports just about every language, but with varying levels of
                        quality based on the AI's training. The performance quality depends on
                        the amount of training data available. While major languages are
                        well-supported, you may experience lower levels of accuracy with less
                        common languages.
                    </p>
                </div>
                <div className="faq-item">
                    <h3 className="faq-question">Why is pronunciation not taught?</h3>
                    <p className="faq-answer">
                        While pronunciation is important, so long as you can be understood, you
                        can have a conversation. Perfect pronunciation won't help you if you
                        simply don't have the vocabulary necessary to understand or
                        communicate. Once you're able to hold conversations with real people
                        and actually use the language, you'll be far more motivated to continue
                        learning. Also, talking to real people will help you learn how words
                        are actually pronounced, rather than its textbook pronunciation.
                    </p>
                </div>
                <div className="faq-item">
                    <h3 className="faq-question">Why is reading and writing not taught?</h3>
                    <p className="faq-answer">
                        The goal of the app is to get you to be conversational as fast as
                        possible. Reading and writing is not typically needed in verbal
                        conversations. Also, other resources likely do a far better job at
                        teaching the reading and writing system. You'll likely be able to learn
                        to read and write just by using the app, but it's not explicitly
                        taught.
                    </p>
                </div>
                <div className="faq-item">
                    <h3 className="faq-question">Why does the app not use the IPA?</h3>
                    <p className="faq-answer">
                        The app converts the target language script into the English alphabet
                        (transliteration) instead of the IPA (International Phonetic Alphabet),
                        because learning the IPA is likely just as hard as learning the target
                        language's writing system. Any time spent on learning the IPA is likely
                        better spent on learning the target language writing system instead.
                    </p>
                </div>
            </div>
        </div>
    );
}

function FinalCTASection() {
    return (
        <div className="cta-section">
            <h2 className="cta-heading">Join our Discord Community</h2>
            <p>
                Join our server to gain exclusive beta access, get the latest updates,
                and share your feedback.
            </p>
            <a
                href="https://discord.gg/jnNM6Jmn"
                target="_blank"
                rel="noopener noreferrer"
                className="cta-button-link"
            >
                <md-filled-button className="cta-button">Join Discord</md-filled-button>
            </a>
        </div>
    );
}

/* function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <nav className="footer-nav">
                    <a href="/">About Us</a>
                    <a href="/">Contact</a>
                    <a href="/">Privacy Policy</a>
                    <a href="/">Terms of Service</a>
                    {<a href="/about">About Us</a>
                    <a href="/contact">Contact</a>
                    <a href="/privacy">Privacy Policy</a>
                    <a href="/terms">Terms of Service</a>}
                </nav>
                <div className="social-media">
                    <a href="https://facebook.com">Facebook</a>
                    <a href="https://twitter.com">Twitter</a>
                    <a href="https://instagram.com">Instagram</a>
                </div>
                <div className="newsletter-signup">
                    <input type="email" placeholder="Subscribe to our newsletter" />
                    <button type="submit">Subscribe</button>
                </div>
            </div>
        </footer>
    );
} */

export default WelcomePage;
