import React, { useEffect, useContext, useCallback, useMemo, useState } from "react";
import axios from "axios";
import WebSocketService from "../../context/WebSocketService";

import MessageContainer from "./MessageContainer";
import { GlobalContext } from "../../context/GlobalContext";

function ConversationArea({
    preferences,
    setPreferences,
    conversationHistory,
    setConversationHistory,
    conversationDetails,
}) {
    const { auth } = useContext(GlobalContext);
    const { userId, userType } = auth;
    const [activeMessageId, setActiveMessageId] = useState(null);
    const [wordBank, setWordBank] = useState([]);
    const [expandedMessageId, setExpandedMessageId] = useState(null);
    const [selectedMorphemes, setSelectedMorphemes] = useState([]);
    const [anchorMessageId, setAnchorMessageId] = useState(null);
    const [deleteConfirm, setDeleteConfirm] = useState(false);

    useEffect(() => {
        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
            document.documentElement.classList.add("ios");
        }
    }, []);

    // Fetch initial morphemes.
    const fetchMorphemes = useCallback(async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/get-morphemes`,
                {
                    withCredentials: true,
                }
            );
            const sortedData = response.data.morphemes.sort(
                (a, b) => b.trueCount - a.trueCount
            );
            const masteryThreshold = response.data.masteryThreshold;
            setPreferences((prev) => ({
                ...prev,
                masteryThreshold,
            }));
            setWordBank(sortedData);
            setAnchorMessageId(response.data.anchorMessageId);
            return sortedData;
        } catch (error) {
            console.error("Error fetching morphemes:", error);
            throw error;
        }
    }, [setPreferences]);

    useEffect(() => {
        const { conversationHistory } = conversationDetails;
        const lastMessageId = conversationHistory[conversationHistory.length - 1]?.id || null;
        setAnchorMessageId(lastMessageId);
    }, [conversationDetails]);

    useEffect(() => {
        fetchMorphemes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addSnapshotToHistory = useCallback(
        (snapshot) => {
            const {
                senderId,
                target: messageId,
                fieldName,
                status,
                statusMessage,
                value,
                senderType,
                conversationId,
            } = snapshot;
            if (messageId === "scenarioSnapshot") return;
            if (conversationId && conversationId !== conversationDetails.conversationId)
                return;
            setConversationHistory((prevHistory) => {
                // Work on a shallow copy so we can reassign positions as needed.
                let updatedHistory = [...prevHistory];
                const messageExists = updatedHistory.some((msg) => msg.id === messageId);
                if (messageExists) {
                    // Update an existing message.
                    updatedHistory = updatedHistory.map((msg) =>
                        msg.id === messageId
                            ? { ...msg, [fieldName]: { status, statusMessage, value } }
                            : msg
                    );
                } else {
                    // First, check for a temporary message for the same sender.
                    const tempIndex = updatedHistory.findIndex(
                        (msg) => msg.id === `temp-${senderType}`
                    );
                    if (tempIndex !== -1) {
                        // Replace the temporary message with the new completed one.
                        updatedHistory[tempIndex] = {
                            ...updatedHistory[tempIndex],
                            senderId,
                            id: messageId,
                            senderType,
                            [fieldName]: { status, statusMessage, value },
                        };
                    } else {
                        // Determine the insertion point.
                        // For a non-temp message, if the last message is a temp message
                        // from the opposite sender, insert the new message just before it.
                        const otherSenderType = senderType === "user" ? "assistant" : "user";
                        if (
                            updatedHistory.length > 0 &&
                            updatedHistory[updatedHistory.length - 1].id ===
                                `temp-${otherSenderType}`
                        ) {
                            updatedHistory.splice(updatedHistory.length - 1, 0, {
                                id: messageId,
                                senderId,
                                senderType,
                                [fieldName]: { status, statusMessage, value },
                            });
                        } else {
                            // Otherwise, append to the end.
                            updatedHistory.push({
                                id: messageId,
                                senderId,
                                senderType,
                                [fieldName]: { status, statusMessage, value },
                            });
                        }
                    }
                }
                return updatedHistory;
            });
        },
        [conversationDetails.conversationId, setConversationHistory]
    );

    useEffect(() => {
        const handleTargetMessage = (data) => {
            //console.log("data", data);
            addSnapshotToHistory(data);
        };
        WebSocketService.on("message:target", handleTargetMessage);
        return () => {
            WebSocketService.off("message:target", handleTargetMessage);
        };
    }, [addSnapshotToHistory]);

    const processMorphemeDetails = (morphemeDetails = {}) =>
        Object.values(morphemeDetails)
            .map(([originalMorpheme, transliteratedMorpheme, translatedMorpheme]) => ({
                originalMorpheme,
                transliteratedMorpheme,
                translatedMorpheme,
            }))
            .filter(
                ({ originalMorpheme, transliteratedMorpheme }) =>
                    originalMorpheme && transliteratedMorpheme
            );

    // Calculate opacity based on cumulative count and masteryThreshold.
    function calculateOpacity(cumulativeCount, masteryThreshold) {
        if (cumulativeCount <= 0) {
            /*console.debug(
                `calculateOpacity: cumulativeCount=${cumulativeCount} (<=0), opacity=0`
            ); */
            return 1;
        }
        const opacity = 1 - Math.min(cumulativeCount / masteryThreshold, 1);
        /*console.debug(
            `calculateOpacity: cumulativeCount=${cumulativeCount}, masteryThreshold=${masteryThreshold}, opacity=${opacity}`
        ); */
        return opacity;
    }

    // Process "old" messages (from anchor backwards) with selected morpheme highlighting.
    function processOldMessages(
        oldMessages,
        baseWordCounts,
        masteryThreshold,
        selectedMorphemes
    ) {
        const cumulativeCounts = { ...baseWordCounts };
        const selectedSet = new Set(selectedMorphemes.map((m) => m.toLowerCase()));
        const processedReversed = oldMessages
            .slice()
            .reverse()
            .map((msg) => {
                const newMsg = { ...msg };
                const morphemes = msg.morphemeTranslationDetails?.value
                    ? processMorphemeDetails(msg.morphemeTranslationDetails.value)
                    : [];
                const updatedMorphemes = morphemes.map((morpheme, index) => {
                    const key = `${msg.id}-old-${index}`;
                    const mKey = morpheme.originalMorpheme.toLowerCase();
                    const opacity = calculateOpacity(
                        cumulativeCounts[mKey] || 1,
                        masteryThreshold
                    );
                    cumulativeCounts[mKey] = (cumulativeCounts[mKey] || 0) - 1;
                    const newTranslated = opacity === 0 ? "" : morpheme.translatedMorpheme;
                    return {
                        ...morpheme,
                        opacity,
                        key,
                        translatedMorpheme: newTranslated,
                        isSelected: selectedSet.has(mKey),
                    };
                });
                newMsg.morphemeArray = updatedMorphemes;
                return newMsg;
            });
        const processedOldMessages = processedReversed.reverse();
        return { processedOldMessages, cumulativeCounts };
    }

    // Process "new" messages (after anchor) with selected morpheme highlighting.
    function processNewMessages(
        newMessages,
        baseWordCounts,
        masteryThreshold,
        selectedMorphemes
    ) {
        const cumulativeCounts = { ...baseWordCounts };
        const selectedSet = new Set(selectedMorphemes.map((m) => m.toLowerCase()));
        const processedNewMessages = newMessages.map((msg) => {
            const newMsg = { ...msg };
            const morphemes = msg.morphemeTranslationDetails?.value
                ? processMorphemeDetails(msg.morphemeTranslationDetails.value)
                : [];
            const updatedMorphemes = morphemes.map((morpheme, index) => {
                const key = `${msg.id}-new-${index}`;
                const mKey = morpheme.originalMorpheme.toLowerCase();
                cumulativeCounts[mKey] = (cumulativeCounts[mKey] || 0) + 1;
                const opacity = calculateOpacity(cumulativeCounts[mKey], masteryThreshold);
                /*                 console.debug(
                    `NewMessage: msgId=${msg.id}, morpheme=${mKey}, count=${cumulativeCounts[mKey]}, opacity=${opacity}`
                ); */
                const newTranslated = opacity === 0 ? "" : morpheme.translatedMorpheme;
                return {
                    ...morpheme,
                    opacity,
                    key,
                    translatedMorpheme: newTranslated,
                    isSelected: selectedSet.has(mKey),
                };
            });
            newMsg.morphemeArray = updatedMorphemes;
            return newMsg;
        });
        return { processedNewMessages, cumulativeCounts };
    }

    // Process the entire conversation by splitting into old (up to anchor) and new messages.
    function processConversationOpacity(
        conversationHistory,
        baseWordCounts,
        masteryThreshold,
        anchorMessageId,
        selectedMorphemes
    ) {
        const anchorIndex = conversationHistory.findIndex((msg) => msg.id === anchorMessageId);
        if (anchorIndex === -1) {
            const { processedNewMessages } = processNewMessages(
                conversationHistory,
                baseWordCounts,
                masteryThreshold,
                selectedMorphemes
            );
            return processedNewMessages;
        }
        const oldMessages = conversationHistory.slice(0, anchorIndex + 1);
        const newMessages = conversationHistory.slice(anchorIndex + 1);
        const { processedOldMessages } = processOldMessages(
            oldMessages,
            baseWordCounts,
            masteryThreshold,
            selectedMorphemes
        );
        const { processedNewMessages } = processNewMessages(
            newMessages,
            baseWordCounts,
            masteryThreshold,
            selectedMorphemes
        );
        return processedOldMessages.concat(processedNewMessages);
    }

    const formattedConversationHistory = useMemo(() => {
        if (!Array.isArray(conversationHistory)) {
            console.error("Invalid conversation history.");
            return [];
        }
        // Build base counts from the word bank.
        const baseWordCounts = wordBank.reduce((acc, word) => {
            const key = word.originalMorpheme.toLowerCase();
            acc[key] = word.effectiveCount;
            return acc;
        }, {});
        return processConversationOpacity(
            conversationHistory,
            baseWordCounts,
            preferences.masteryThreshold,
            anchorMessageId,
            selectedMorphemes
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        conversationHistory,
        wordBank,
        preferences.masteryThreshold,
        anchorMessageId,
        selectedMorphemes,
    ]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const optionsContainer = document.querySelector(".message-options-container");
            if (!optionsContainer) return;
            if (!optionsContainer.contains(event.target)) {
                setActiveMessageId(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleCopyTargetMessage = useCallback(() => {
        if (!activeMessageId) return;
        const message = conversationHistory.find((msg) => msg.id === activeMessageId);
        if (!message) return;
        const textToCopy = message.targetLanguageMessage?.value || "";
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {})
            .catch((error) => {
                console.error("Failed to copy message:", error);
            });
    }, [activeMessageId, conversationHistory]);

    const handleDeleteMessage = useCallback(async () => {
        if (!activeMessageId) return;

        // If confirmation not yet activated, set it and return
        if (!deleteConfirm) {
            setDeleteConfirm(true);
            return;
        }

        // If already confirmed, perform deletion
        try {
            await axios.delete(
                `${process.env.REACT_APP_API_URL}/delete-message/${activeMessageId}`,
                { withCredentials: true }
            );
            // Optionally, clear the active message to close the menu
            setActiveMessageId(null);
        } catch (error) {
            console.error("Error deleting message:", error);
        }
    }, [activeMessageId, deleteConfirm]);

    // Reset confirmation when the menu closes
    useEffect(() => {
        if (activeMessageId === null) {
            setDeleteConfirm(false);
        }
    }, [activeMessageId]);

    const handleFullSentenceTranslation = useCallback(async () => {
        if (!activeMessageId) return;
        setActiveMessageId(null);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/translate-sentence`, {
                messageId: activeMessageId,
            });
        } catch (error) {
            console.error("Error translating sentence:", error);
        }
    }, [activeMessageId]);

    const handleWordOptions = useCallback((messageId) => {
        setActiveMessageId(null);
        setExpandedMessageId((prev) => (prev === messageId ? null : messageId));
    }, []);

    const renderMessageOptions = useCallback(() => {
        // Find the active message from the conversation history.
        const activeMessage = conversationHistory.find((msg) => msg.id === activeMessageId);
        // Determine if the message can be deleted.
        // Admins ignore restrictions.
        const canDelete =
            userType === "admin" ||
            (activeMessage &&
                (activeMessage.senderType === "assistant" ||
                    (activeMessage.senderType === "user" &&
                        activeMessage.senderId === userId)));

        return (
            <div className={`message-options-container ${activeMessageId ? "visible" : ""}`}>
                {canDelete && (
                    <md-outlined-button
                        className="button"
                        onClick={handleDeleteMessage}
                        title={deleteConfirm ? "Are you sure?" : "Delete Message"}
                        aria-label={deleteConfirm ? "Are you sure?" : "Delete Message"}
                    >
                        <md-icon
                            style={{
                                transform: "translateY(11.5%)",
                                fontSize: "1rem",
                                color: "var(--md-sys-color-error)",
                            }}
                        >
                            delete
                        </md-icon>
                        <span style={{ color: "var(--md-sys-color-error)" }}>
                            {deleteConfirm ? "Are you sure?" : "Delete Message"}
                        </span>
                    </md-outlined-button>
                )}

                <md-outlined-button
                    className="button"
                    onClick={handleCopyTargetMessage}
                    title="Copy Message"
                    aria-label="Copy Message"
                >
                    <md-icon style={{ transform: "translateY(11.5%)", fontSize: "1rem" }}>
                        content_copy
                    </md-icon>
                    Copy Message
                </md-outlined-button>

                <md-outlined-button
                    className="button"
                    onClick={handleFullSentenceTranslation}
                    title="Translate Message"
                    aria-label="Translate Message"
                >
                    <md-icon style={{ transform: "translateY(11.5%)", fontSize: "1rem" }}>
                        translate
                    </md-icon>
                    Full Sentence Translation
                </md-outlined-button>

                <md-outlined-button
                    className="button"
                    onClick={() => handleWordOptions(activeMessageId)}
                    title="Word Options"
                    aria-label="Word Options"
                >
                    <md-icon style={{ transform: "translateY(11.5%)", fontSize: "1rem" }}>
                        build_circle
                    </md-icon>
                    Word Options
                </md-outlined-button>
            </div>
        );
    }, [
        activeMessageId,
        handleDeleteMessage,
        deleteConfirm,
        handleCopyTargetMessage,
        handleFullSentenceTranslation,
        handleWordOptions,
        conversationHistory,
        userId,
        userType,
    ]);

    return (
        <div className="conversation-area">
            <div className="scroll-wrapper scrollable">
                <div className="message-list">
                    {formattedConversationHistory.map((message) => (
                        <MessageContainer
                            key={message.id}
                            isExpanded={expandedMessageId === message.id}
                            message={message}
                            preferences={preferences}
                            setActiveMessageId={setActiveMessageId}
                            wordBank={wordBank}
                            expandedMessageId={expandedMessageId}
                            setExpandedMessageId={setExpandedMessageId}
                            selectedMorphemes={selectedMorphemes}
                            setSelectedMorphemes={setSelectedMorphemes}
                            fetchMorphemes={fetchMorphemes}
                        />
                    ))}
                </div>
            </div>
            {activeMessageId !== null && renderMessageOptions()}
        </div>
    );
}

export default ConversationArea;
