import React, { useState, useEffect } from "react";
import axios from "axios";

const InviteFriendDialog = ({ dialogRefInvite }) => {
    const [inviteCode, setInviteCode] = useState("TEST12");

    useEffect(() => {
        fetchInviteCode();
    }, []);

    const fetchInviteCode = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/get-invite-code`,
                { withCredentials: true }
            );
            setInviteCode(response.data.code);
        } catch (error) {
            console.error("Error fetching invite code:", error);
        }
    };

    const handleReplaceInviteCode = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/replace-invite-code`,
                { withCredentials: true }
            );
            setInviteCode(response.data.code);
        } catch (error) {
            console.error("Error replacing invite code:", error);
        }
    };

    const handleCopyCode = (event) => {
        event.preventDefault();
        navigator.clipboard
            .writeText(inviteCode)
            .catch((error) => console.error("Error copying invite code:", error));
    };

    const handleShareCode = (event) => {
        event.preventDefault();
        const inviteLink = `${window.location.origin}/register?invite=${inviteCode}`;
        navigator.clipboard
            .writeText(inviteLink)
            .then(() => {
                console.log("Invite link copied to clipboard:", inviteLink);
            })
            .catch((error) => console.error("Error copying invite link:", error));
    };

    const closeDialog = () => {
        if (dialogRefInvite && dialogRefInvite.current) {
            dialogRefInvite.current.open = false;
        }
    };

    return (
        <div className="invite-friend-dialog">
            <md-dialog ref={dialogRefInvite}>
                <span slot="headline" className="headline">
                    Invite a Friend
                    <md-icon-button
                        className="dialog-close-button"
                        value="close"
                        aria-label="Close invite dialog"
                        onClick={closeDialog}
                    >
                        <md-icon>close</md-icon>
                    </md-icon-button>
                </span>
                <form className="invite-friend-form" slot="content" method="dialog">
                    <div>
                        <md-filled-text-field
                            label="Your Invite Code"
                            value={inviteCode}
                            readOnly
                        />
                    </div>
                    <md-outlined-button onClick={handleReplaceInviteCode}>
                        Replace Invite Code
                    </md-outlined-button>
                    <md-outlined-button onClick={handleCopyCode}>Copy Code</md-outlined-button>
                    <md-outlined-button onClick={handleShareCode}>Share</md-outlined-button>
                </form>
            </md-dialog>
        </div>
    );
};

export default InviteFriendDialog;
