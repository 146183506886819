import React, { useState, useContext, forwardRef, useImperativeHandle } from "react";
import axios from "axios";
import AutoResizedTextField from "../../components/mdc/AutoResizedTextField.js";
import { GlobalContext } from "../../context/GlobalContext";

const MessageInput = forwardRef((props, ref) => {
    const { auth } = useContext(GlobalContext);
    const { userId } = auth;
    const [message, setMessage] = useState(localStorage.getItem("myInputValue") || "");
    const [isSending, setIsSending] = useState(false);

    // Expose a reset function to the parent via ref
    useImperativeHandle(ref, () => ({
        reset() {
            setMessage("");
            localStorage.removeItem("myInputValue");
        },
    }));

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setMessage(inputValue);
        localStorage.setItem("myInputValue", inputValue);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const trimmedMessage = message.trim();
        if (!trimmedMessage || isSending) return;

        setIsSending(true);
        setMessage("");

        // Immediately update conversation history with temporary messages.
        props.setConversationHistory((prevHistory) => {
            // Remove any existing temporary messages.
            const newHistory = prevHistory.filter(
                (msg) => msg.id !== "temp-user" && msg.id !== "temp-assistant"
            );

            let updatedHistory = [];

            // If the user's target language is "Not set",
            // the temporary user message shows as done (i.e. pre-translated).
            if (props.preferences.language === "Not set") {
                updatedHistory = [
                    ...newHistory,
                    {
                        id: "temp-user",
                        senderId: userId,
                        senderType: "user",
                        targetLanguageMessage: { status: "done", value: trimmedMessage },
                    },
                ];
            } else {
                // Otherwise, show the user message as loaded (original message) with target set as connecting.
                updatedHistory = [
                    ...newHistory,
                    {
                        id: "temp-user",
                        senderId: userId,
                        senderType: "user",
                        originalMessage: { status: "loaded", value: trimmedMessage },
                        targetLanguageMessage: { status: "connecting" },
                    },
                ];
            }
            // If conversationDetails doesn't exist OR the conversationType is neither "direct" nor "group"
            if (
                !props.conversationDetails ||
                (props.conversationDetails.conversationType !== "direct" &&
                    props.conversationDetails.conversationType !== "group")
            ) {
                updatedHistory.push({
                    id: "temp-assistant",
                    senderType: "assistant",
                    targetLanguageMessage: { status: "connecting" },
                });
            }
            return updatedHistory;
        });

        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/send-message`,
                {
                    message: trimmedMessage,
                    conversationId: props.conversationDetails.conversationId,
                },
                { withCredentials: true }
            );
        } catch (error) {
            console.error("Error sending message:", error);
        } finally {
            setIsSending(false);
            localStorage.removeItem("myInputValue");
        }
    };

    return (
        <form className="message-form" onSubmit={handleSubmit}>
            <AutoResizedTextField
                variant="outlined"
                id="userInput"
                type="textarea"
                value={message}
                rows="1"
                placeholder="Type your mixed language message..."
                onInput={handleInputChange}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                        handleSubmit(e);
                        e.preventDefault();
                    }
                }}
            />
            <md-icon-button type="submit" className="send-button" softDisabled={isSending}>
                <md-icon filled>send</md-icon>
            </md-icon-button>
        </form>
    );
});

export default MessageInput;
