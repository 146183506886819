import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { GlobalContext } from "../../context/GlobalContext";


import "../../App.css";
import "./RegisterAndLoginPages.css";

function ResetPasswordPage() {
    return (
        <div className="app login-register">
            <ResetPasswordManager />
        </div>
    );
}

const ResetPasswordManager = () => {
    const navigate = useNavigate();
    const { auth, preferences } = useContext(GlobalContext);

    const [feedbackMessage, setFeedbackMessage] = useState("");

    const passwordInputRef = useRef(null); // Reference for the password input field
    const retypePasswordInputRef = useRef(null); // Reference for the retype password input field

    useEffect(() => {
        import(`../../themes/${preferences.theme}.css`)
            .then(() => {})
            .catch((err) => {
                console.error(`Failed to load ${preferences.theme} theme`, err);
            });
        document.body.className = preferences.theme;
    }, [preferences.theme]); // Re-run effect when theme changes

    async function resetPassword(newPassword) {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/reset-password`,
                { newPassword },
                {
                    withCredentials: true, // This ensures that cookies (including JWT) are sent
                }
            );

            const result = response.data;
            //console.log("reset password result: ", result);

            switch (response.status) {
                case 200:
                    setFeedbackMessage("Password reset successfully!");
                    navigate("/conversation"); // Navigate to the main app page after successful password reset
                    break;
                case 400:
                    setFeedbackMessage(
                        result.message ||
                            "Invalid request. Please ensure all fields are correctly filled."
                    );
                    break;
                case 401:
                    setFeedbackMessage(
                        result.message || "User authentication required. Please log in again."
                    );
                    navigate("/login"); // Navigate to the login page if the user is not authenticated
                    break;
                case 404:
                    setFeedbackMessage(result.message || "User not found. Please try again.");
                    break;
                default:
                    setFeedbackMessage(
                        result.message ||
                            "An error occurred during password reset. Please try again."
                    );
                    break;
            }
        } catch (error) {
            // This catch block is for handling errors in the fetch operation itself, such as network issues.
            console.error("Network or server error:", error);
            alert(
                "Unable to connect to the server. Please check your internet connection and try again."
            );
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        const password = passwordInputRef.current.value; // Get the current value of the password input
        const retypePassword = retypePasswordInputRef.current.value; // Get the current value of the retype password input

        // Check if the password is at least 8 characters long
        if (password.length < 8) {
            setFeedbackMessage("Password must be at least 8 characters long.");
            return; // Stop the function if the condition is not met
        }
        // Check if both passwords match
        if (password !== retypePassword) {
            setFeedbackMessage("Passwords do not match.");
            return; // Stop the function if the condition is not met
        }
        // Call the reset password function
        resetPassword(password);
    };

    const handlePasswordChange = (event) => {
        passwordInputRef.current.value = event.target.value;
        //console.log("password field changed: ", passwordInputRef.current.value);
    };

    return (
        <>
            <div className="login-register-container">
                <h1>Reset Your Password</h1>
                <h2>{auth.username}</h2>
                <form
                    id="resetPasswordForm"
                    method="dialog"
                    onSubmit={handleSubmit}
                >
                    <div className="input-container">
                        <md-outlined-text-field
                            label="Create a New Password"
                            id="resetPasswordInput"
                            type="password"
                            onInput={handlePasswordChange}
                            ref={passwordInputRef}
                            placeholder="New Password"
                            required
                            editable
                            aria-label="Create New Password"
                        />
                    </div>
                    <div className="input-container">
                        <md-outlined-text-field
                            label="Retype New Password"
                            id="retypeResetPasswordInput"
                            type="password"
                            ref={retypePasswordInputRef}
                            placeholder="Retype New Password"
                            required
                            editable
                            aria-label="Retype New Password"
                        />
                    </div>
                    <div className="form-actions">
                        <md-filled-button type="submit">Reset Password</md-filled-button>
                    </div>
                </form>
                <div className="error-message">{feedbackMessage}</div>
                <Link to="/home">Homepage</Link>
            </div>
        </>
    );
};

export default ResetPasswordPage;
