import React, { createContext, useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import WebSocketService from "./WebSocketService";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        isAuthenticated: false,
        accessToken: null,
        username: null,
        userId: null,
        userType: null,
        affiliatedOrganization: null,
    });
    const [preferences, setPreferences] = useState({
        language: null,
        showTransliteration: null,
        theme: "dark",
        masteryThreshold: null,
    });
    const [loading, setLoading] = useState(true);

    // Use a ref to store the promise so that it persists across renders.
    const refreshPromiseRef = useRef(null);

    // Wrap refreshAccessToken in useCallback to ensure a stable reference.
    const refreshAccessToken = useCallback(async () => {
        if (refreshPromiseRef.current) {
            return refreshPromiseRef.current;
        }
        refreshPromiseRef.current = axios
            .post(
                `${process.env.REACT_APP_API_URL}/refresh-token`,
                {},
                { withCredentials: true }
            )
            .then((response) => {
                if (response.data.valid) {
                    const { accessToken, username, userId, userType, affiliatedOrganization } =
                        response.data;
                    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
                    setAuth({
                        isAuthenticated: true,
                        accessToken,
                        username,
                        userId,
                        userType,
                        affiliatedOrganization,
                    });
                    return true;
                } else {
                    console.warn("Refresh token returned an invalid response:", response.data);
                    setAuth({
                        isAuthenticated: false,
                        accessToken: null,
                        username: null,
                        userId: null,
                        userType: null,
                        affiliatedOrganization: null,
                    });
                    return false;
                }
            })
            .catch((error) => {
                console.warn("Refresh token request failed:", error);
                setAuth({
                    isAuthenticated: false,
                    accessToken: null,
                    username: null,
                    userId: null,
                    userType: null,
                    affiliatedOrganization: null,
                });
                return false;
            })
            .finally(() => {
                refreshPromiseRef.current = null;
            });
        return refreshPromiseRef.current;
    }, []); // empty dependency array ensures that refreshAccessToken is memoized

    // On mount, attempt to refresh the token.
    useEffect(() => {
        (async () => {
            const success = await refreshAccessToken();
            if (!success) {
                console.log(
                    "Initial refresh token attempt failed. User will be considered not authenticated."
                );
            } else {
                console.log("Initial refresh token attempt succeeded.");
            }
            setLoading(false);
        })();
    }, [refreshAccessToken]);

    // Refresh the token when the browser comes online.
    useEffect(() => {
        const handleOnline = () => {
            console.log("Browser is online. Refreshing access token...");
            refreshAccessToken();
        };
        window.addEventListener("online", handleOnline);
        return () => window.removeEventListener("online", handleOnline);
    }, [refreshAccessToken]);

    // Axios interceptor for auto-refreshing the token on each request.
    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use(
            async (config) => {
                if (config.url && config.url.includes("/refresh-token")) {
                    return config;
                }
                const token = config.headers.Authorization?.split(" ")[1];
                if (token) {
                    try {
                        const decoded = jwtDecode(token);
                        if (decoded.exp * 1000 < Date.now()) {
                            const response = await axios.post(
                                `${process.env.REACT_APP_API_URL}/refresh-token`,
                                {},
                                { withCredentials: true }
                            );
                            if (response.data && response.data.valid) {
                                const newAccessToken = response.data.accessToken;
                                config.headers.Authorization = `Bearer ${newAccessToken}`;
                                setAuth((prevAuth) => ({
                                    ...prevAuth,
                                    accessToken: newAccessToken,
                                }));
                                axios.defaults.headers.common[
                                    "Authorization"
                                ] = `Bearer ${newAccessToken}`;
                            }
                        }
                    } catch (error) {
                        console.error("Error decoding token or refreshing:", error);
                    }
                }
                return config;
            },
            (error) => Promise.reject(error)
        );
        return () => {
            axios.interceptors.request.eject(requestInterceptor);
        };
    }, []);

    const login = async (username, password, rememberMe) => {
        // If a different user is already logged in, log them out first.
        if (auth.isAuthenticated && auth.username !== username) {
            await logout();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify({ username, password, rememberMe }),
            });
            const result = await response.json();
            if (response.ok && result.loginSuccess) {
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${result.accessToken}`;
                setAuth({
                    isAuthenticated: true,
                    accessToken: result.accessToken,
                    username: result.username,
                    userId: result.userId,
                    userType: result.userType,
                    affiliatedOrganization: result.affiliatedOrganization,
                });
                WebSocketService.connectWebSocket();
                return { success: true };
            }
            return { success: false, message: result.message, status: response.status };
        } catch (error) {
            console.log("error", error);
            return { success: false, message: "Login failed. Please try again." };
        }
    };

    const logout = async () => {
        let pushSubscription = null;
        if ("serviceWorker" in navigator && "PushManager" in window) {
            try {
                const registration = await navigator.serviceWorker.ready;
                pushSubscription = await registration.pushManager.getSubscription();
            } catch (error) {
                console.error("Error fetching push subscription:", error);
            }
        }
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/logout`,
                { pushSubscription }, // Include the current push subscription
                { withCredentials: true }
            );
        } catch (error) {
            console.error("Error during logout while removing refreshToken cookie:", error);
        }
        // Disconnect the websocket connection on logout
        WebSocketService.closeWebSocket();

        localStorage.removeItem("myInputValue");
        setAuth({
            isAuthenticated: false,
            accessToken: null,
            username: null,
            userId: null,
            userType: null,
            affiliatedOrganization: null,
        });
        delete axios.defaults.headers.common["Authorization"];
    };

    const value = { auth, login, logout, preferences, setPreferences, refreshAccessToken };

    if (loading) return <div>Loading...</div>;
    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};
